html, body, #root {
  height: 100%;
  overflow: hidden;
  font-family: 'DINOT', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.leaflet-touch .leaflet-bar {
  border: 0px solid rgba(0, 0, 0, 0.2);
}
.App {
  text-align: center;
}
.menu-bar-item-cat-1{
  font-size:20px;
  line-height:0.9;
  letter-spacing: -0.2px;
  font-weight: bold !important;
  @media screen and (max-width: 1375px) {
    font-size:16px;
  }
  @media screen and (max-width: 1175px) {
    font-size:12px;
  }
  @media screen and (max-width: 975px) {
    font-size:10px;
  }
}
.menu-bar-item-cat-2{
  font-size:14px;
  line-height:0.9;
  @media screen and (max-width: 1375px) {
    font-size:12px;
  }
  @media screen and (max-width: 1175px) {
    font-size:10px;
  }
  @media screen and (max-width: 975px) {
    font-size:8px;
  }
}
/* Top Navbar styles */
.top-navbar {
 
  background-color: #FFC423;
  color: #231f20;
  height: 50px; 
  display: flex;
  /*justify-content: space-between;*/
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  .magnify-search-button {
    margin-left: auto;
    margin-right: 10px;
    cursor: pointer;
  }
  .navbar-title, .moa-logo {
    float:left;
    padding-left:20px;
    font-size:25pt;
  }
  .moa-logo {
    margin-top: 7px;
  }
  .menu-toggle {
    cursor: pointer;
    z-index: 1001; /* Above the sidebar */
    margin-left: 10px;
  }

  .bar {
    width: 40px;
    height: 3.5px;
    background-color: #231f20;
    margin: 6px 0;
    border-radius: 2px;
  }
}
.search-results{
  width: calc(100% - 250px);
  height: calc(100% - 50px);
  background-color: #fffffff3;
  position: absolute;
  z-index: 55;
  top: 50px;
  transition: left 0.3s ease-in-out;
}
.search-results-show{
  left: 250px;
}
.search-results-hide{
  left: 100%;
}

.fullscreen-popup {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100% - 50px);
  background-color: white; /* Semi-transparent black background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it appears above all other content */
  color: #231f20;
}

.popup-header {
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button {
  font-size: 24px;
  background: none;
  color: #231f20;
  border: none;
  cursor: pointer;
}

.popup-body {
  text-align: center;
  font-size: 20px;
  padding: 20px;
}

.show-menu.main-content {
  transform: translateX(0px); 
}
.main-content {
  width:calc(100% + 250px);
  height:100%;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-250px); 
  /*display: -webkit-inline-box;*/
  display: flex;
  position:relative;
 .search-content{
    width: 100%;
    height: 100%;
    background-color: #000;
    color: #fff;
    .search-button{
      justify-content: center;
      align-items: center;
      border: 1px solid #ffffff;
    }
    .search-button:hover{
      color: #a29f9f;
      border: 1px solid #a29f9f;
    }
  }
  .main-container{
    width:100vw;
    height:calc(100% - 50px);
    position: relative;
    top: 50px;
    display:flex;
  }
  .main-container-mobile{
    width:100vw;
    height: calc(100vh - 50px);
    position: relative;
    top: 50px;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    .mobile-header-section{
      background-color: rgba(228, 172, 172, 0.3);
      padding-bottom: 50px;
      .mobile-image{
        margin-left:30px;
        margin-right:30px;
        margin-bottom:20px;
      }
      .mobile-header{
        color: #000;
        //font-family: Karla;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 60%;
        margin-left: 20%;
        margin-right: 20%;
        margin-top:20px;
        margin-bottom:20px;
        text-align: center;
        opacity: 0.8;
        background: rgba(255, 255, 255, 0.80);
      }
      .mobile-paragraph{
        color: #000;
        //font-family: Karla;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left:15px;
        margin-right:15px;
        margin-bottom:20px;
      }
    }
    .title{
      color: #000;
      //font-family: Karla;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 100%;
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .description{
      color: #000;
      //font-family: Karla;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top:20px;
      padding-left:10px;
      padding-right:10px;
      padding-bottom:60px;
      span{
        text-decoration: underline;
        text-decoration-line: underline;
      }
    }
    svg{
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .sidebar {
    background-color: #ffffff;
    overflow: auto;
    width: 250px;
    position: relative;
    z-index: 2000;
    top: 50px;
    height: calc(100% - 50px);
    font-size: 19px;
    display:flex;
    .nav-links {
      list-style: none;
      padding: 0;
      margin: 0;
      padding-top: 60px; /* Space for top navbar */
      margin:15px;
      width:100%;
      .nav-links-li{
        cursor:pointer;
      }
    }

    .nav-links li {
      padding: 15px;
      border-top:1px solid #231f20;
    }
    .nav-links li:last-child {
      border-bottom: 1px solid #231f20;
    }

    .nav-links li a {
      text-decoration: none;
      color: #231f20;
      display: block;
    }
  }
}

/* RSlider Container */
.r-slider-container {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 20px auto;
  text-align: left;
}

/* Title */
.r-title {
  font-size: 16px;
  font-weight:700;
  margin-bottom: 60px;

}

/* Slider Wrapper */
.r-slider-wrapper {
  position: relative;
}

/* Gradient */
.r-gradient {
  height: 18px;
  background: linear-gradient(to right, #FFC425, #D3D3D3, #FFC425);
  border-radius: 0px;
  position: relative;
  margin: 0px 0;
}

/* Tooltip */
.r-tooltip {
  position: absolute;
  top: -28px; /* Adjusted to make room for labels */
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000 !important;
  color: #fff !important;
  font-size: 12px;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  width: 75px;
  line-height: 10px;
  border: 2pt solid #000;
}

.r-tooltip-notch {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #22AF4C; /* Default */
  margin-top: 4px;
  z-index: 1;
  border-top-color: #000 !important;
}
.r-tooltip-notch-under {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #9E9C9C; /* Default */
  border-top-color: #000 !important;
  margin-top: 4px;
  margin-top: -7px;
  z-index: 0;
}

/* Scale labels (-1, 0, 1) */
.r-scale-labels {
  display: flex;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 10px;
    color: #333;
    position: relative;
    top: -22px;
    padding-left: 5px;
    padding-right: 5px;
}

/* Strong/Moderate/Weak labels */
.r-labels-above {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  font-size: 10px;
  color: #333;
}

/* Description */
.r-description {
  font-size: 10px;
  font-weight:700;
  margin-top: 0px;
}

.r-description a {
  color: black;
  text-decoration: underline;
}
.active-option{
  input[type='radio']:after {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    top: 0px;
    left: 0px;
    position: relative;
    background-color: #FFFFFF;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #000000;
  }

  input[type='radio']:checked:after {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    top: 0px;
    left: 0px;
    position: relative;
    background-color: #000000;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #000000;
  }
}
.inactive-option{
  input[type='radio']:after {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    top: 0px;
    left: 0px;
    position: relative;
    background-color: #FFFFFF;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #9E9C9C;
  }

  input[type='radio']:checked:after {
    width: 13px;
    height: 13px;
    border-radius: 13px;
    top: 0px;
    left: 0px;
    position: relative;
    background-color: #9E9C9C;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid #9E9C9C;
  }
}
.leaflet-right{
  z-index: 999;
}
.location-col{
  padding-left:32px;
  padding-top:32px;
  .location-col-header{
    border: 2px solid #000;
    background: var(--Background, #FAF5F5);
    display: inline-flex;
    height: 25px;
    padding: 5px 0px 5px 13px;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .location-col-p{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 233px;
    width: 100%;
    //height: 100%;
    flex-shrink: 0;
  }
  .disclaimer-col-p{
    padding-top:20px;
    color: #000;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    max-width: 233px;
    width:100%;
    //height: 100%;
    flex-shrink: 0;
  }
  .location-col-header-title{
    max-width: 233px;
    width: 100%;
    //height: 100%;
    flex-shrink: 0;
    color: #231F20;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .location-col-img{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 233px;
    width:100%;
    //height: 100%;
    flex-shrink: 0;
  }
}
.custom-search-label{
  opacity: 0.75;
  .leaflet-popup-content-wrapper{
    .leaflet-popup-content{
      margin: 2.5px 5px 2.5px 10px;
    }
  }
}